<script>
import {bus} from '../../../router/bus';
import { Splitpanes, Pane } from "splitpanes";
import { request_list_grid_sets } from "../../jsons/gridsets";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import ruMessages from "devextreme/localization/messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";
import "splitpanes/dist/splitpanes.css";
import CVRequestSideForm from "@/components/request-form/CVRequestSideForm";
// import CVTBDateInput from "../../../common/CVTBDateInput";
import RateCardShort from "../../rate-card/RateCardShort.vue";
import OrderRequestForm from "../../request-form/OrderRequestForm.vue";
import NotPayedInvoicesTab from "./finance-tab/FinanceTabCabinet.vue";
import CVArchiveList from "./CVArchiveList.vue";
// import OrderNumberEditForm from "./rate-edit-form/OrderNumberEditForm.vue";
// import GoodEditForm from "./rate-edit-form/GoodEditForm.vue";
// import ConsigneeInfoForm from "../../request-form/order-form-tabs/ConsigneeInfoForm.vue";
// import InvoiceInfoForm from "../../request-form/order-form-tabs/InvoiceInfoForm.vue";
// import AutoDeliveryInfoForm from "../../request-form/order-form-tabs/AutoDeliveryInfoForm.vue";

import {
  DxDataGrid,
  // DxColumn,
  // DxButton,
  // DxFilterPanel,
  // DxFilterBuilderPopup,
  DxScrolling,
  DxColumnFixing,
  DxStateStoring,
  DxSummary,
  DxTotalItem,
  DxHeaderFilter,
  DxSearchPanel,
  DxColumnChooser,
  // DxEditing,
  // DxPopup,
  // DxPosition,
  // DxColumn,
  // DxGrouping,
  // DxGroupPanel,
  DxExport,
  DxPager,
  DxPaging,
} from "devextreme-vue/data-grid";

export default {
  name: "ListCopy",
  
  components: {
    CVArchiveList,
    DxDataGrid,
    // DxColumn,
    // DxButton,
    // DxFilterPanel,
    // DxFilterBuilderPopup,
    DxScrolling,
    DxColumnFixing,
    DxStateStoring,
    DxLoadPanel,
    DxSummary,
    DxTotalItem,
    DxHeaderFilter,
    DxSearchPanel,
    DxColumnChooser,
    DxPager,
    DxPaging,
    Splitpanes,
    RateCardShort,
    Pane,
    // OrderNumberEditForm,
    // DeliveryEditForm,
    // GoodEditForm,
    // CVTBDateInput,
    CVRequestSideForm,
    // CVRequestMobileCard,
    OrderRequestForm,
    NotPayedInvoicesTab,
    // DxColumn,
    // DxGrouping,
    // DxGroupPanel,
    DxExport,
    // DxEditing,
    // DxPopup,
    // DxPosition,
  },
  
  data: () => ({
    component_name: "CVRequestList",
    dataSource: [],
    archiveRates: [],
    analogRates: [],
    identical_rate_key: null,
    indexOfIdenticalRate: null,
    dataDocuments: {},
    columns: request_list_grid_sets,
    showHeaderFilter: true,
    autoExpandAll: true,
    currentMode: "widget",
    sync: true,
    showRight: false,
    showOptions: false,
    rowAlternationEnabled: true,
    filterValue: "",
    dataGridRefName: "CVRequestListDataGrid",
    dataGridRefName2: "CVRequestListDataArchGrid",
    popupVisible: false,
    loadingVisible: false,
    selected_status: null,
    filterBuilderPopupPosition: {
      of: window,
      at: "top",
      my: "top",
      offset: { y: 10 },
    },
  
    send_rate_request_data: [],
    showOrderRequestForm: false,
    showEditForm: false,
    selectedReqType: null,
    selectedRow: null,
    
    on_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    
    dates: [],
    D: new Date().setMonth(new Date().getMonth() -6),
    date_from: '',
    date_to: new Date(),
    elements: {
      date_from: {
        caption: "С",
        name: "date_from",
        disabled: false,
        alt: "",
        type: "date"
      },
    
      date_to: {
        caption: "По",
        name: "date_to",
        disabled: false,
        alt: "",
        type: "date"
      },
    },
    
    statuses: [
      {
        id: 0,
        title: "Ожидание груза"
      },
      {
        id: 1,
        title: "Груз готов"
      },
      {
        id: 21,
        title: "Выход в море уточняется"
      },
      {
        id: 2,
        title: "Вышел в море"
      },
      {
        id: 31,
        title: "Прибыл в порт трансшипмента"
      },
      {
        id: 3,
        title: "Прибыл в порт"
      },
      {
        id: 41,
        title: "Стоит на рейде"
      },
      {
        id: 42,
        title: "Выгружен в порту"
      },
      {
        id: 43,
        title: "Подан в таможню"
      },
      {
        id: 4,
        title: "Выпущен из таможни"
      },
      {
        id: 5,
        title: "Отправлен по жд"
      },
      {
        id: 6,
        title: "Прибыл на станцию"
      },
      {
        id: 7,
        title: "Выгружен у клиента"
      }
    ],
  }),

  watch:{
    selected_status(value) {
      if (value == null) {
        this.$refs[this.dataGridRefName].instance.clearFilter();
        this.filterValue = '';
      }
    }
  },
  
  computed: {
    isOrderTab() {
      return Number(this.$_getsetting('is_view_orders_block'))
    },
    
    isFinanceTab() {
      return Number(this.$_getsetting('is_view_fin_block'))
    },

    visibleColumns() {
      if ((+this.$_getsetting('user_id') === 974 && +this.$_getsetting('client_id') === 1036) ||
        (+this.$_getsetting('user_id') === 431 && +this.$_getsetting('client_id') === 792)) {
        return this.columns.filter(column => column.dataField !== 'status');
      }
      return this.columns;
    },
  },

  mounted() {
    this.loadingVisible = true
    this.getItemsData()
    this.clearStatusFilter()
    this.dataToString()
    
    this.hideStateIdColumn()
  
    bus.$on("show_send_request", (args) => {
      this.send_rate_request_data = args
      this.is_showvars = false
      this.showOrderRequestForm = true
    });
  },

  created() {
    loadMessages(ruMessages);
    locale(navigator.language);
  },

  methods: {
    hideStateIdColumn() {
      this.columns.push({"dataField":"state_id", "caption":"ID статуса", "dataType":"number", "allow-editing": false, visible: false},)
    },

    onContentReady(e) {
      const grid = e.component;
      // Принудительно устанавливаем порядок колонок, если нужно
      grid.columnOption("status", "visibleIndex", 3); // Порядок сохраняется
    },
    
    ord_id_text(data){
      return data.value.toLocaleString()
    },
    
    dataToString() {
      var d = new Date();
      var from = d.setMonth(d.getMonth() - 6);
      this.date_from = d
      console.log(from)
      let out_date = new Date(this.on_date)
      out_date.setDate(out_date.getDate());
      this.date_to = out_date
    },
    
    getItemsData() {
      this.$postapi(this.$address + this.$getters.getActiveOrdersList.uri, {
        method: this.$getters.getActiveOrdersList.name,
        client_id: this.$_getsetting('client_id')
      }).then(data => {
        if (data.error == 0) {
          this.dataSource = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
          this.loadingVisible = false
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    clearStatusFilter() {
      if (this.$refs[this.dataGridRefName] != undefined) {
        const dataGrid = this.$refs[this.dataGridRefName].instance;
        dataGrid.clearFilter()
        this.filterValue = ''
        this.selected_status = null
      }
    },
    
    clearArchStatusFilter() {
      if (this.$refs[this.dataGridRefName2] != undefined) {
        const dataGrid = this.$refs[this.dataGridRefName2].instance;
        dataGrid.clearFilter()
        this.filterValue = ''
        this.selected_status = null
      }
    },

    addStatusFilter(id) {
      if (this.$refs[this.dataGridRefName] != undefined) {
        const dataGrid = this.$refs[this.dataGridRefName].instance;
        switch (id) {
          case '0' : {
            dataGrid.clearFilter();
            this.filterValue = '';
          }
          break
          default: {
            this.filterValue =  [['state_id', '=', id]];
          }
          break
        }
      }
    },

    onRowClick(order_rate) {
      this.dataDocuments = {};
      this.loadingVisible = true
      this.getOrder(order_rate)
      this.getCopyOrderAnalogRate(order_rate)
      this.showRight = true
    },
    
    getOrder(order_rate) {
      this.$postapi(this.$address + this.$getters.getOrder.uri, {
        method: this.$getters.getOrder.name,
        ord_id: order_rate.data.ord_id
      }).then(data => {
        if (data.error == 0) {
          let rowData = JSON.parse(data.message)[0];
          this.dataDocuments = rowData
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
  
    getCopyOrderAnalogRate(order_rate) {
      this.$postapi(this.$address + this.$getters.getCopyOrderAnalogRate.uri, {
        method: this.$getters.getCopyOrderAnalogRate.name,
        ord_id: order_rate.data.ord_id
      }).then(data => {
        if (data.error == 0) {
          this.analogRates = JSON.parse(data.message);
          this.identical_rate_key = this.analogRates.identical_rate
          this.loadingVisible = false
          if (this.analogRates.rates != null) {
            this.indexOfIdenticalRate = this.analogRates.rates.findIndex(el => el.key_orig === this.identical_rate_key);
          } else {
            this.indexOfIdenticalRate = null
          }
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
  
    OnSave(value, n = 5) {
      this.$postapi(this.$address + this.$setters.setPrefRequestData.uri, {
        method: this.$setters.setPrefRequestData.name,
        rate: value,
        client_id: this.$_getsetting('user_id'),
        token: this.$_getsetting("token")
      }).then(data => {
        if (data.message != 0 && data.message != null) {
          this.showSendLoading = false
          bus.$emit("show_notify", {
            color: "success",
            notifytext: "Ваша заявка создана! Ожидайте письмо с подтверждением"
          });
        
          setTimeout(() => {
            this.showOrderRequestForm = false
          }, 900);
        }
      
        else {
          if (n > 0) {
            this.showSendLoading = true
            this.saveAttemptCount = Math.abs(n-6)
          
            setTimeout(() => {
              this.OnSave(value, n-1)
            }, 1500);
          } else {
            this.saveAttemptCount = 6
          }
        }
      });
    },
    
    setUpdateOrderRequest() {
      this.$postapi(this.$address + this.$setters.setUpdateOrderRequest.uri, {
        method: this.$setters.setUpdateOrderRequest.name,
        token: this.$_getsetting('token'),
        client_id: this.$_getsetting('client_id'),
        req_type: this.selectedReqType,
        data: this[this.selectedReqType],
        ord_id: this.selectedRow.ord_id
      }).then(data => {
        if (data.error == 0) {
          bus.$emit("show_notify", {
            color: "success",
            notifytext: 'Изменения внесены!'
          });
          this.showEditForm = false
          this.selectedReqType = null
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
  }
};
</script>

<template>
  <div class="list-container">
    <div class="request-list">
      <v-dialog
        v-model="showOptions"
        v-if="analogRates.rates != null"
        persistent
        width="60%"
        transition="dialog-bottom-transition"
        @click:outside="showOptions = false"
      >
        <div class="request-list__card-dialog" v-if="showOptions === true">
          <div class="request-list__card-dialog-similar">Идентичная ставка</div>
          <RateCardShort
            style="border: 4px solid var(--main-orange)"
            :rate="analogRates.rates[indexOfIdenticalRate]"
          />
          
           <div class="request-list__card-dialog-analog" v-if="Object.keys(analogRates.rates).length > 1">Аналогичные ставки:</div>
  
          <div
            v-for="(item, index) in analogRates.rates"
            :key="index"
          >
            <RateCardShort
              v-if="index != indexOfIdenticalRate"
              :rate="item"
            />
          </div>
        </div>
      </v-dialog>
      
      <v-dialog
        v-model="showOrderRequestForm"
        persistent
        width="80%"
        transition="dialog-bottom-transition"
      >
        <OrderRequestForm
          v-if="showOrderRequestForm === true"
          class="request-form"
          :order="analogRates.order"
          :args="send_rate_request_data"
          @close="showOrderRequestForm = false"
          @save="OnSave"
        />
      </v-dialog>
    
      <splitpanes class="default-theme">
        <pane min-size="40" class="request-list__pane">
          <div
            class="request-list__pane__container"
          >
            <v-tabs
              class="request-list__pane__container-tabs"
              color="var(--bg-dark)"
            >
              <v-tab v-if="isOrderTab === 0" @click="getItemsData">Логистика</v-tab>
              <v-tab v-if="isFinanceTab === 0">Финансы</v-tab>
              <v-tab class="request-list__pane__container-tabs-archive">
                Архив
              </v-tab>
              
              <v-select
                :items="statuses"
                label="Статус.."
                class="request-list__pane__container-tabs-select"
                color="var(--main-bg-color)"
                item-color="var(--main-bg-color)"
                v-model="selected_status"
                item-text="title"
                item-value="id"
                clearable
                @change="addStatusFilter"
                solo
                dense
                height="45"
              />
              
              <v-tab-item v-if="isOrderTab === 0">
                <div class="request-list__pane__container">
                  <v-btn
                    class="request-list__pane__container-tabs-clear-filter"
                    color="#EF8159"
                    text
                    elevation="0"
                    dense
                    x-small
                    @click="clearStatusFilter()"
                  >
                    Очистить фильтры
                  </v-btn>
                  
                  <div v-if="analogRates.rates != null">
                    <v-btn
                      v-if="Object.keys(this.dataDocuments).length > 0 && analogRates.rates.length"
                      class="request-list__pane__container-tabs-clear-filter copy-btn"
                      color="#EF8159"
                      text
                      x-small
                      elevation="0"
                      dense
                      @click="showOptions = true"
                    >
                      Копировать заказ
                    </v-btn>
                  </div>
                  
                  
                  <div class="request-list__pane__container-grid">
                    <DxLoadPanel
                      style="z-index: 3020 !important;"
                      :visible.sync="loadingVisible"
                      :show-indicator="true"
                      :show-pane="true"
                      :shading="true"
                      :close-on-outside-click="false"
                    />
                    
                    <DxDataGrid
                      style="z-index: 3010 !important;"
                      width="100%"
                      key-expr="ord_id"
                      :hoverStateEnabled="true"
                      id="mt-request-list"
                      :columns="visibleColumns"
                      :ref="dataGridRefName"
                      :selection="{ mode: 'single' }"
                      :data-source="dataSource"
                      :word-wrap-enabled="false"
                      :allow-column-reordering="true"
                      :show-borders="true"
                      :focused-row-enabled="true"
                      :allow-column-resizing="true"
                      :column-resizing-mode="currentMode"
                      :row-max-height="10"
                      :column-auto-width="true"
                      :filter-value="filterValue"
                      :filter-sync-enabled="sync"
                      :row-alternation-enabled="rowAlternationEnabled"
                      @row-dbl-click="onRowClick"
                      @row-click="showRight === true ? onRowClick($event) : ''"
                      @content-ready="onContentReady"
                    >
<!--                      <DxEditing-->
<!--                        mode="popup"-->
<!--                        :allow-updating="true"-->
<!--                        @click="clickEdit"-->
<!--                      >-->
<!--                      </DxEditing>-->
                      
                      <DxScrolling mode="standard"/>
        
                      <DxPaging :page-size="1000"/>
        
                      <DxPager
                        :visible="false"
                      />
        
                      <DxStateStoring
                        :enabled="true"
                        type="localStorage"
                        storage-key="mt-request-list"
                      />
        
                      <DxHeaderFilter
                        :visible="showHeaderFilter"
                      />
                      
                      <DxExport
                        :enabled="true"
                        :allow-export-selected-data="true"
                        class="mt-4"
                        file-name="mt-request-list"
                      />
                      
                      <DxColumnChooser class="mt-2" :enabled="true"/>
        
                      <DxColumnFixing :enabled="true"/>
        
                      <!--              <DxGroupPanel :visible="true"/>-->
                      <!--              <DxGrouping :auto-expand-all="autoExpandAll"/>-->
        
<!--                                      <DxFilterPanel-->
<!--                                        id="mt-archive-filter-panel"-->
<!--                                        :visible="true"-->
<!--                                      />-->
        
                      <!--                <DxFilterBuilderPopup-->
                      <!--                  :position="filterBuilderPopupPosition"-->
                      <!--                />-->
        
                      <DxSearchPanel
                        id="mt-archive-search"
                        :visible="true"
                        :width="240"
                        placeholder="Найти..."
                      />
                      
                      <DxSummary>
                        <DxTotalItem
                          :customize-text="ord_id_text"
                          column="ord_id"
                          summary-type="count"
                        />
          
                        <DxTotalItem
                          :customize-text="ord_id_text"
                          column="amount_sum"
                          summary-type="sum"
                          value-format="#,##0.00"
                        />
          
                        <DxTotalItem
                          :customize-text="ord_id_text"
                          column="pay_sum"
                          summary-type="sum"
                          value-format="#,##0.00"
                        />
          
                        <DxTotalItem
                          :customize-text="ord_id_text"
                          column="dolg"
                          summary-type="sum"
                          value-format="#,##0.00"
                        />
        
                      </DxSummary>
                    </DxDataGrid>
                  </div>
                </div>

              </v-tab-item>
  
              <v-tab-item v-if="isFinanceTab === 0">
                <NotPayedInvoicesTab class="request-list__pane__container-tabs-finance"/>
              </v-tab-item>
              
              <v-tab-item>
                <CVArchiveList
                  @onRowClick="onRowClick"
                />
              </v-tab-item>
          
            </v-tabs>
          </div>
        </pane>
        
        <pane
          min-size="5"
          v-if="showRight"
          class="request-list__pane"
        >
          <div class="info-block">
            <CVRequestSideForm
              @close="showRight = false"
              :document="dataDocuments"
            />
          </div>
       </pane>
      </splitpanes>
    </div>
    
  </div>
</template>

<style scoped lang="scss">
@import '../../../../node_modules/devextreme/dist/css/dx.common.css';
@import '../../../../node_modules/devextreme/dist/css/dx.material.orange.light.css';

.dx-datagrid .dx-column-indicators .dx-header-filter.dx-header-filter::after,
.dx-datagrid .dx-column-indicators .dx-sort.dx-header-filter::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0px;
}

body::-webkit-scrollbar {
  width: 12px;               /* ширина всей полосы прокрутки */
}

body::-webkit-scrollbar-track {
  background: orange;        /* цвет зоны отслеживания */
}

body::-webkit-scrollbar-thumb {
  border-radius: 20px;       /* округлось бегунка */
}

.list-container {
  z-index: 3020;
}

.request-list {
  border-radius: 10px;
  margin-top: -1px;
  margin-left: 1%;
  margin-right: 1%;
  //overflow: hidden !important;
  overflow-x: visible;
  z-index: 3020;
  
  @media (max-width: 750px) {
    display: none;
  }
  
  &__card-dialog {
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 20px 0;
    
    &-similar {
      color: var(--main-orange);
      font-weight: 700;
      margin: 0 0 -10px 25px;
    }
    
    &-analog {
      color: black;
      font-weight: 600;
      margin-left: 25px;
      font-size: 18px;
    }
  }
  
  &__pane {
    padding: 0;
    border-radius: 5px;
    background-color: #FFFFFF;
    border-width: 3px 1px 1px 1px;
    border-style: solid;
    border-color:  #e8e8e8;
    height: 88vh;
    white-space: nowrap;
    overflow-x: auto !important;
    left: -5px;
    box-shadow: 0px 3px 8px rgba(9, 30, 66, 0.16), 0px 0px 1px rgba(9, 30, 66, 0.31);

    @include font-subtitle-medium;
    
    @media (min-width: 1800px) {
      height: 92vh;
    }
  
    //@media (min-width: 1800px) {
    //  height: calc(102% - 80px);
    //}
    
    &__container {
      display: flex;
      background-color: #fff;
      margin: 0;
      white-space: nowrap;
      overflow-x: auto !important;
      width: 100%;
      
      &-arch {
        display: flex;
        z-index: 200;
        margin-top: -16px;
        
        &-title {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 27px;
          margin: 5px 10px 0 -10px;
          letter-spacing: 0.01em;
          color: #000000;
        }
  
        &-input {
          width: 200px !important;
          margin: 0 20px !important;
          height: 40px !important;
        }
        
        &-btn {
          text-transform: none;
          margin: 0px 0 0 10px;
          height: 50px;
          letter-spacing: 0.7px;
        }
      }

      &-tabs {
        margin-left: 10px;
        width: 90%;

        @include font-subtitle-medium;
        
        &-archive {
          position: absolute;
          padding-bottom: 2px;
          height: 50px;
          right: 260px;
          z-index: 200;
        }
        
        &-finance {
          margin: 0;
          //height: 80vh;
          width: 100%;
          overflow-y: scroll !important;
        }

        &-select {
          position: absolute;
          right: 0px;
          margin-top: 2px;
          width: 250px !important;

          @include font-body-2;
        }
        
        &-clear-status {
          position: absolute;
          top: 5px;
          width: 35px !important;
          text-transform: none;
          right: 255px;
          margin-top: 2px;
        }
        
        &-clear-filter, &-clear-arch-filter {
          position: absolute;
          z-index: 200;
          text-transform: none;
          left: 0;
          padding: 16px 8px !important;
          font-weight: 500;
          letter-spacing: 0.07em;
          font-size: 14px;
          line-height: 20px;
          border: solid 1px #EF8159;
          color: #EF8159;
        }
        
        &-clear-filter {
          top: 25px;
        }
        
        &-clear-arch-filter {
          top: 80px;
        }
      }

      &__tab {
        color: var(--bg-dark) !important;
        //text-transform: none;
        height: 35px;
        margin: 5px 3px -7px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding: 8px 15px;
        border: 1px solid var(--main-bg-color);
        border-radius: 4px;

        &-active {
          background: var(--bg-dark);
          color: #FFFFFF !important;
          border: 1px solid #FFFFFF;
        }
      }

      &-divider {
        width: 120%;
        position: relative;
        height: 2px;
        left: -20px;
        background-color: #DFDDDD;
        margin: -8px 0 5px -10%;
      }

      &-grid {
        width: 100%;
        margin: 10px 0 0 ;
        height: calc(100vh - 180px);
        overflow: visible;
        
        @include font-subtitle-regular;
        
        &__menu-list {
          border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        }
      }
    }
    
    &__archive {
      margin-top: -33px;
      display: flex;
      background: none;
      white-space: nowrap;
      height: 80%;
      overflow-x: auto !important;
      width: 100%;
      
      &__ttl {
        margin: 10px 0 0 20px;
        font-size: 20px;
      }
    }
  }
}

.edit-form {
  padding: 40px;
  
  &-btn-save {
    text-transform: none;
    font-weight: 700;
    color: white;
    margin-top: -20px;
  }
}

.request-list-mobile {
  display: none;
  padding: 20px 10px 4px;
  
  &:not(:first-child) {
    padding-top: 3px;
  }
  
  @media (max-width: 750px) {
    display: block;
  }
}

#mt-archive-search {
  top: -20px;
}

.copy-btn {
  margin-left: 174px;
}

#mt-request-list, #mt-archive-form {
  height: 100%;
  background: rebeccapurple;
  white-space: nowrap !important;
  overflow-x: auto !important;
}

.mt-editor-form{
  background-color: #fff;
}

.splitpanes.default-theme .splitpanes__pane {
  background-color: #ffffff;
}
.close-guid-btn {
  text-transform: none;
  position: fixed;
  z-index: 20000000000000000000;
  bottom: 15%;
  left: calc(50% - 70px);
}

</style>
